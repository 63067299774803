
.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

body {
  background: linear-gradient(-45deg, #4c642d,#51739b, #584085, #a43931);
	background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.blurred-box {
  position: relative;
  min-height: 235px;
  background: inherit;
  border-radius: 2px;
  overflow: hidden;
  width: 85%;
  height: fit-content;
  padding-bottom: 16px;
  margin: auto;
  border-radius: 10px;
  font-family: 'Cousine', sans-serif;
  border: 1px ridge white;
  z-index: 1;
  margin-bottom: 28px;
  /* Small Devices, Tablets */
  @media only screen and (min-width : 333px) {
    width: 80%;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width : 813px) {
    width: 75%;
    min-height: 228px;
  }

  h1 {  
    line-height: 1.2;
    font-size: 42px;
    font-family: 'Viga', sans-serif;
    font-weight: bold;
    color: white;
    margin: 0;
    padding: 0 2px 2px 2px;
    padding-top: 18px;
    /* Small Devices, Tablets */
    @media only screen and (min-width : 324px) {
      font-size: 40px;
    }
    @media only screen and (min-width : 425px) {
      font-size: 45px;
    }
    @media only screen and (min-width : 680px) {
      font-size: 54px;
    }
  }
  h2 {
    font-family: 'Viga', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: white;
    margin: 0;
    line-height: 1.2;
    padding-bottom: 8px;
    @media only screen and (min-width : 680px) {
      font-size: 40px;
    }
  }
  hr {
    border-top: 1px ridge white;
    width: 100%;
    margin: auto;
  }
  p {
    color: white;
    font-size: 17px;
    padding: 1em;
    word-wrap: none;
    margin-bottom: 0;
    font-family: 'Viga', sans-serif;
  }
}

.blurred-box:after{
  content: '';
  min-height: 270px;
  background: inherit; 
  position: absolute;
  left: -25px;
  right: 0;
  top: -25px;  
  bottom: 0;
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.082);
  filter: blur(10px);
  pointer-events: none;
}

.mdl-layout__header {
  background-color: unset!important;
}

.navigation {
  font-family: 'Viga', sans-serif;
  font-size: 13px!important;
  :hover {
    transform: scale(1.2);
  }
}

.landing-grid {
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  @media only screen and (min-width : 840px) {
    padding-bottom: 90px!important;
  }
}

.avatar-img {
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  padding-bottom: 20px;
  padding-top: 28px;
  -webkit-filter: grayscale(100%) drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7)); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
}

.avatar-img:hover {
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7)); /* Safari 6.0 - 9.0 */
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}

.social-links {
  z-index: 1;
  i {
    color: whitesmoke;
    font-size: 3em;
    padding: 0.2em;
  }
  i:hover {
    color: white;
    transform: scale(1.1);
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
  }
}

.social-links-footer {
  z-index: 1;
  i {
    color: whitesmoke;
    font-size: 6em;
    padding: 0 0.2em 0 0;
  }
  i:hover {
    color: white;
    transform: scale(1.1);
  }
  @media only screen and (min-width : 840px) {
    text-align: left;
    padding-left: 2em;
  }
}

.projects-grid {
  display: flex;
}

.contact-body {
  margin: auto;
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
  margin-top: 22px;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  min-height: 250px;
  color: white;
  margin-bottom: 20px;
  margin: auto;
  padding-top: 22px;
  font-family: 'Viga', sans-serif;
  border-top: white solid;
  border-bottom: white solid;
  @media only screen and (min-width : 330px) {
    padding: 20px 8px 8px 8px !important;
  }
  @media only screen and (min-width : 522px) {
    padding: 60px 8px 0px 8px !important;
  }
  @media only screen and (min-width : 1001px) {
    padding: 60px 8px 0px 8px !important;
  }
  h2 {
    text-decoration: underline;
    font-family: 'Viga', sans-serif;
    font-size: 38px;
    line-height: 0.5em;
    @media only screen and (min-width : 840px) {
      width: 83%;
      text-align: left;
    }
  }
  p {
    font-family: 'Viga', sans-serif;
  }
}

.contact-details {
  font-family: 'Viga', sans-serif;
  font-size: 20px;
  //padding: 0.8em;
  h2 {
    text-decoration: underline;
    font-style: bold;
  }
  a {
    font-family: 'Viga', sans-serif;
    padding-left: 8px;
    font-size: 13px;
    @media only screen and (min-width : 1025px) {
      font-size: 15px;
    }
  }
  i {
    margin-bottom: 6px;
    color: white!important;
  }
}

.footer {
  text-align: center;
  height: auto;
  padding: 25px;
  padding-top: 35px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  color: whitesmoke;
}

.mdl-layout__drawer-button {
  @media only screen and (min-width : 1025px) {
    display: none!important;
    }   
}
.material-icons {
  color: white;
  font-size: 34px!important;
}

.mdl-cell {
  margin: 0!important;
}

.mdl-card {
  //width: width;
  margin: 1em!important;
}

.mdl-list__item {
  white-space: nowrap;
  padding: 0!important;
  font-size: 12px!important;
}

.mdl-list__item-icon {
  margin-right: 8px!important;
  font-size: 12px;
}

.mdl-tabs__tab-bar {
  padding-left: 25px;
  height: 48px;
    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
      height: 0;
    }
  
    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
      display: none;
    }
}

.mdl-tabs__tab {
  color: white!important;
  padding: 0 6px 0 6px!important;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
   background: white!important;
}

.about-me {
  color: white;
  padding: 3em;
  font-family: 'Viga', sans-serif;
  border-top: white solid;
  border-bottom: white solid;
  h2 {
    margin-top: 8px;
    text-decoration: underline;
    font-family: 'Viga', sans-serif;
  }
  p {
    font-size: 15px;
  }
}

.header {
  color: white;
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  margin-right: 0.4em;
  padding-top: 25px;
  padding-bottom: 35px;
  h2 {
    font-family: 'Viga', sans-serif;
    text-decoration: underline;
    padding-left: 12px;
    @media only screen and (min-width : 522px) {
      padding-left: 0;
    }
  }
  .site-name {
    padding-left: 0;
  }
}
.sites-worked-on {
  max-width: 95%;
  margin: 0 auto;
  margin-right: 0.4em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 0.9em;
  @media only screen and (min-width : 321px) {
    margin-right: 0.4em;
    grid-gap: 0;
  }
  @media only screen and (min-width : 522px) {
    grid-gap: 0 1.4rem;
    margin: 0;
    margin-left: 0.3em;
  }
  @media only screen and (min-width : 863px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding-bottom: 35px;
  }
  @media only screen and (min-width : 1300px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
  .sites-section {
    display: none;
  }

  .sites {
    display: block;
    @media only screen and (min-width : 572px) {
      display: flex;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h2 {
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin: 0;
    line-height: 1.2;
    text-align: center;
    display: block;
    width: 100%;
  }
  .react-card-flipper {
    padding: 0.5em;
    width: 90%;
    margin: 0 auto;
    padding-left: 10px;
    padding-bottom: 0px;
    @media only screen and (min-width : 580px) {
      padding-bottom: 23px;
    }
    @media only screen and (min-width : 652px) {
      padding: 0.5em;
    }
  }
  @media only screen and (min-width : 768px) {
    .CardFront, .CardBack {
      flex: auto;
      margin: 0 8px 8px 0; /* Some gutter */
      width: 360px;
      margin: 0 auto;
    }
  }
  .card-detail {
    height: 100%;
    h2 {
      font-family: 'Barlow Semi Condensed', sans-serif;
      font-size: 20px;
      font-weight: bold;
      color: black;
      margin: 0;
      line-height: 1.2;
      text-align: center;
      padding-bottom: 4px;
    }
    p {
      font-family: 'Barlow Semi Condensed', sans-serif;
      margin-bottom: 10px;
      @media only screen and (min-width : 580px) {
        margin-bottom: 0;
      }
    }
    .button {
      width: 53%; //46
      text-decoration: none;
      font-family: 'Barlow Semi Condensed', sans-serif;
    }
  }

  a.button {
    display: block;
    border-radius: 5px;
    background-color: #2e2e2e;
    border: white solid;
    border-width: 2px;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    padding: 0.5em;
    transition: all 0.5s;
    width: 34%;
    cursor: pointer;
    margin: auto;
  }
  
  a.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    @media only screen and (min-width : 768px) {
      padding-right: 20px;
    }
  }
  
  a.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  a.button:hover {
    background-color: #424242;
  }
  
  a.button:hover span {
    padding-right: 25px;
  }
  
  a.button:hover span:after {
    opacity: 1;
    right: 0;
  }
}
.sites-worked-on > div:last-child {
  @media only screen and (min-width : 1001px) {
    display: none;
  }
  @media only screen and (min-width : 1300px) {
    display: block;
  }
  @media only screen and (min-width : 1317px) {
    display: none;
  }
  @media only screen and (min-width : 1759px) {
    display: block;
  }
}

.cardStyleBack {
  height: 250px;
  border-radius: 30px;
  border: #2e2e2e;
  color: #000;
  background-color: #fff;
  margin: auto;
  max-width: 370px;
  cursor: pointer;
  @media only screen and (min-width : 989px) {
    max-width: 300px;
  }
  @media only screen and (min-width : 1060px) {
    max-width: 335px;
  }
}
.cardStyleBack:hover {
  -webkit-filter: drop-shadow(7px 7px 5px rgba(0,0,0,0.7));
  filter: drop-shadow(7px 7px 5px rgba(0,0,0,0.7));
}

.cardStyleFront {
  height: 250px;
  border-radius: 30px;
  border: #2e2e2e;
  color: #000;
  background-color: #fff;
  margin: auto;
  max-width: 370px;
  cursor: pointer;
  @media only screen and (min-width : 989px) {
    max-width: 300px;
  }
  @media only screen and (min-width : 1060px) {
    max-width: 335px;
  }
}
.cardStyleFront:hover {
  -webkit-filter: drop-shadow(7px 7px 4px rgba(0,0,0,0.7));
  filter: drop-shadow(7px 7px 4px rgba(0,0,0,0.7));
}

.twitter {
  padding-left: 40px;
}